import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Patienteninfo() {
	return (
		<Layout>
			<SEO
				keywords={[
					`Physiotherapie`,
					`Wien`,
					`1010`,
					`movendos`,
					`Manualtherapie`,
					`Manuelle Therapie`
				]}
				title="Patienteninfo"
			/>
			<div className="bg-gray-200 p-4">
				<h1 id="patienteninfoActive-1" className="text-lg text-primary mb-4">Terminvereinbarung</h1>
				<p>
					Termine kannst du gerne <a href="#kontakt" className='text-primary hover:text-copper' >telefonisch</a> oder per <a className='text-primary hover:text-copper' href="#kontakt">E-Mail</a>{' '}
					ausmachen.
				</p>
				<p>
					Falls dein Anruf nicht sofort persönlich entgegen genommen werden kann, so hinterlasse bitte
					Name, Nachricht und Telefonnummer auf der Mailbox. Wir rufen verlässlich zurück.
				</p>
				<p>
					Bitte beachte, dass vereinbarte Termine für dich reserviert sind. Solltest du einen
					Behandlungstermin nicht einhalten können, storniere diesen bitte mindestens 24 Stunden vor
					Beginn.
				</p>
				<h1 className="text-lg text-primary mb-4">Erstbesuch</h1>
				<p>Das sollten du bitte zur ersten Therapieeinheit mitnehmen:</p>
				<ul>
					<li>Ärztliche Verordnung</li>
					<li>Bereits vorliegende Befunde (Röntgenbilder, MR-Bilder, Operationsprotokolle etc.)</li>
					<li>Bequeme Kleidung (z. B. Trainingshose)</li>
				</ul>
				<h1 id="patienteninfoActive-2" className="text-lg text-primary mb-4">Verordnung und Bewilligung</h1>
				<p>
					Um mit der Therapie beginnen zu können, benötigst du eine ärztliche Verordnung von deinem
					behandelnden Haus- oder Facharzt. Diese Verordnung solltest du dann vor der ersten
					Therapieeinheit von deiner Krankenkasse bewilligen lassen, um eine Rückerstattung
					beantragen zu können.
				</p>
				<div className="flex flex-wrap">
					<div className="w-full sm:w-2/3 md:w-1/3 p-4">
						<h1>Preise</h1>
						<p style={{ marginBottom: '0.5rem' }}>Physiotherapie</p>
						<table className="w-full mb-0 text-center">
							<thead>
								<tr>
									<th>Dauer</th>
									<th>Preis</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>60 min</td>
									<td>120,00 €</td>
								</tr>
								<tr>
									<td>45 min</td>
									<td>95,00 €</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="w-full md:w-2/3 bg-gray-400 p-4 rounded-lg">
						<h1>Rückerstattung</h1>
						<p className="mb-0">
							Am Ende der Behandlungsserie bekommst du eine Honorarnote/Sammelrechnung, die du
							gemeinsam mit der Verordnung bei deiner Krankenkasse einreichen können. Je nach
							Tarifbestimmungen der jeweiligen Krankenkasse erhaltst du einen Teil der Kosten zurück. Wenn
							du privat- oder zusatzversichert bist, hast du die Möglichkeit, bis zu 100% der
							Behandlungskosten refundiert zu bekommen.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Patienteninfo;
